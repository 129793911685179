<template>
  <b-container class="py-2">
    <b-overlay :show="load" opacity="0.5" no-wrap></b-overlay>
    <b-card class="bs-br">
      <b-form style="max-width: none;" @submit.prevent="addCategory">
        <b-row>
          <!-- Name -->
          <b-col cols="12">
            <b-form-group label="Name *" label-for="name" class="mb-3">
              <b-form-input id="name" type="text"
                v-model="$v.form.name.$model" :class="{'is-invalid': $v.form.name.$error}"></b-form-input>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.name.required">The name is required</strong>
                <strong v-if="!$v.form.name.minLength">The name must have at least {{$v.form.name.$params.minLength.min}} letters</strong>
                <strong v-if="!$v.form.name.maxLength">The name must be less than {{$v.form.name.$params.maxLength.max}} letters</strong>
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="auto">
            <b-button pill class="px-4 fw-bold" variant="primary" type="submit">Save</b-button>
            <b-button pill class="px-4 fw-bold" @click="cancelCreate()">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import { required, minLength, maxLength} from 'vuelidate/lib/validators';

export default {
  name: 'AddCategory',
  data() {
    return {
      form: {
        name: String(),
      },
      load: false
    }
  },
  validations: {
    form: {
      name: { required, minLength: minLength(2), maxLength: maxLength(60) }
    },
  },
  methods: {
    addCategory(){
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
          this.load = true;

          this.$http.post('category', this.form, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
            if (response.status === 200) {
              this.$notify({ group: 'app', text: response.body.message, type: 'success' });
              this.$emit('category_id', response.body.data.id);
            }
            this.load = false;
          }, (error) => {
            if (error.status == 400) {
              if (error.body.response == 'error_form') {
                this.$notify({ group: 'app', text: error.data.message, type: 'error' });
              }
            }
            if (error.status == 500) {
              this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
            }
            this.load = false;
          });
      } else {
        this.$notify({ group: 'app', text: 'All required fields must be filled in', type: 'error' });
      }
    },
    cancelCreate() {
      this.$emit('category_id', null);
      this.$bvModal.hide('create-category');
    }
  }
}
</script>
